#app {
    height: 100%
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

body {
    background: #fff;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

html,
body {
    position: relative;
    height: 100%;
}

#app {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper {
    width: 100%;
    height: calc(100vh - 30px)
}
@media (max-width: 600px) {
    .swiper {
        height: calc(100vh - 110px);
    }
}

.swiper.swiper-cards {
    overflow: hidden !important;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    padding: 2px 10px;

}

.swiper-slide:nth-child(1n) {
    background-color: rgb(247, 247, 247);
    border-radius: 1.8rem;
}

.swiper-slide:nth-child(2n) {
    background-color: rgb(247, 247, 247);
    border-radius: 1.8rem;
}

.swiper-slide:nth-child(3n) {
    background-color: rgb(247, 247, 247);
    border-radius: 1.8rem;
}

.swiper-slide:nth-child(4n) {
    background-color: rgb(247, 247, 247);
    border-radius: 1.8rem;
}

.swiper-slide:nth-child(5n) {
    background-color: rgb(247, 247, 247);
    border-radius: 1.8rem;
}

.swiper-slide:nth-child(6n) {
    background-color: rgb(247, 247, 247);
    border-radius: 1.8rem;
}

.swiper-slide:nth-child(7n) {
    background-color: rgb(247, 247, 247);
    border-radius: 1.8rem;
}

.swiper-slide:nth-child(8n) {
    background-color: rgb(247, 247, 247);
    border-radius: 1.8rem;
}

.swiper-slide:nth-child(9n) {
    background-color: rgb(247, 247, 247);
    border-radius: 1.8rem;
}

.swiper-slide:nth-child(10n) {
    background-color: rgb(247, 247, 247);
    border-radius: 1.8rem;
}